import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "allConstants",
      "allLanguage",
      "allProfile",
      "roleTenures",
      "countries",
      "roles",
      "getCurrentStatus",
      "getSettingsCurrentSection",
      "allSections",
      "genders",
      "affiliation",
      "experience",
      "education"
    ]),
    form: {
      get() {
        return this.$store.state.settings.form;
      },
      set(value) {
        this.$store.commit("SET_SETTINGS_FORM_OBJECT", value);
      }
    },
    language() {
      let searchText = this.allProfile.language
        ? this.allProfile.language
        : "en";
      return this.allLanguage.find(l => l.id === searchText);
    },
    selectedDate() {
      return this.allProfile.date_of_birth;
    }
  },
  methods: {
    setSettingsData() {
      Object.keys(this.form).forEach(key => {
        if (key === "language") {
          this.form[key] = this.language;
        } else {
          this.form[key] = this.allProfile[key];
        }
      });
    },
    onSelect(e, type, s) {
      s[type] = e;
      if (type === "role") {
        if (e.id !== 47) {
          this.form["udf_role"] = "";
        }
      }
      if (type === "gender") {
        if (e.id !== 3) {
          this.form["udf_gender"] = "";
        }
      }
      if (type === "language") {
        this.form.language = e;
      }
    },
    onProfileUpdate(section) {
      this.$store.commit("SET_SETTINGS_CURRENT_STATUS", "LOADING");
      this.$store.commit("SET_SETTINGS_CURRENT_SECTION", section);
      let sections = {
          general: [
            "given_name",
            "family_name",
            "gender",
            "udf_gender",
            "display_name",
            "country",
            "city",
            "date_of_birth"
          ],
          professional_info: [
            "role",
            "udf_role",
            "role_tenure",
            "affiliation",
            "education"
          ],
          learning_interest: ["interests"],
          system_language: ["language"]
        },
        arr = [
          "role",
          "role_tenure",
          "gender",
          "country",
          "language",
          "affiliation",
          "education"
        ],
        obj = {};

      sections[this.getSettingsCurrentSection.id].forEach(f => {
        if (arr.includes(f)) {
          obj[f] = this.form[f] ? this.form[f].id : null;
        } else {
          obj[f] = this.form[f];
        }
      });
      this.$store
        .dispatch("updateProfile", obj)
        .then(() => {
          this.$store.commit("SET_SETTINGS_CURRENT_STATUS", "SAVED");
          this.onClose(this.getSettingsCurrentSection);
          if (this.showCompleteProfile) {
            this.$store.commit("SET_SHOW_COMPLETE_PROFILE", false);
          }
          if (this.getSettingsCurrentSection.id === "system_language") {
            this.$i18n.i18next.changeLanguage(this.form.language.id);
          }
        })
        .catch(() => {
          this.$store.commit("SET_SETTINGS_CURRENT_STATUS", "ERROR");
        });
    },
    getASection(section_id) {
      return this.allSections.find(sec => sec.id === section_id);
    },
    onOpen(section) {
      section.isEdit = true;
      this.$store.commit("SET_SETTINGS_CURRENT_SECTION", section);
      this.setSettingsData();
    },
    onClose(section) {
      section.isEdit = false;
      this.$store.commit("SET_SETTINGS_CURRENT_SECTION", section);
    },

    fileuploadValidation(file) {
      const MAX_FILE_SIZE = 2 * 1024 * 1024;
      const validFormats = ["jpeg", "jpg", "png"];

      if (file.size > MAX_FILE_SIZE) {
        return {
          isValid: false,
          errorMessage: this.$t(
            "settings.my_details.general.image_error.file_size"
          )
        };
      }

      const fileExtension = file.type.split("/")[1].toLowerCase();
      if (!validFormats.includes(fileExtension)) {
        return {
          isValid: false,
          errorMessage: this.$t(
            "settings.my_details.general.image_error.file_format"
          )
        };
      }

      return {
        isValid: true,
        errorMessage: ""
      };
    },
    onDateSelected(initialDate) {
      this.form.date_of_birth = initialDate;
    }
  }
};
